import {
  combine,
  createStore,
  Effect,
  Event,
  Store,
} from 'effector';
import type { APIResponse } from '@metropolis-io/api-fetch';
import {
  addAsyncActionHandlers,
  AsyncStatusState,
  FailPayload,
  FailResponse, // eslint-disable-line @typescript-eslint/no-unused-vars
  SuccessPayload,
  SuccessResponse, // eslint-disable-line @typescript-eslint/no-unused-vars
} from './AsyncActionHandlers';

export type PendingHandler<Params, State> = (
  state: State,
  { params, isPending }: { params: Params, isPending: boolean },
) => State;
export type DoneHandler<Params, State, Result = APIResponse> = (
  state: State,
  { params, result }: { params: Params, result: Result }
) => State;
export type FailHandler<Params, State, Result = APIResponse> = (
  state: State,
  { params, error }: { params: Params, error: Result }
) => State;

export type ReducersHandler<State, Params = any> = { // Can we make Params more specific?
  [actionName: string]: {
    action: Effect<Params, SuccessPayload, FailPayload> | Event<Params>;
    skipAsyncStore?: boolean;
    reducer?: (
      state: State,
      data: any,
    ) => State;
    pending?: (
      state: State,
      isPending: boolean,
    ) => State;
    done?: (
      state: State,
      { params, result }: SuccessResponse,
    ) => State;
    fail?: (
      state: State,
      { error }: FailResponse,
    ) => State;
  }
};

/**
 * Combines the data store and async store and adds reducers
 *
 * @param store - data store
 * @param asyncStore - contains statuses regarding the async request
 * @param reducers - listeners applied to the data store
 * @param storeResetReducers - reset listeners applied to the data store
 * @param asyncStoreResets - reset listeners applied to the async store
 */
export function applyReducers<State>({
  store,
  asyncStore,
  reducers,
  storeResetReducers = [],
  asyncResetReducers = [],
}: {
  store: Store<State>,
  asyncStore?: Store<AsyncStatusState>,
  reducers: ReducersHandler<State> | any,
  storeResetReducers?: Event<any>[],
  asyncResetReducers?: Event<any>[],
}): Store<AsyncStatusState & State> {
  let finalAsyncStore: Store<AsyncStatusState>;
  if (asyncStore) {
    finalAsyncStore = asyncStore;
  } else {
    // Dynamically add statuses for all the actions, if no async store is added
    const asyncStatuses: AsyncStatusState = { status: {}, statusMessage: {} };
    Object.keys(reducers).forEach((actionName) => {
      const { reducer, skipAsyncStore } = reducers[actionName];
      // Do not set statuses if action is an event or skipped
      if (!reducer && !skipAsyncStore) {
        asyncStatuses.status[`${actionName}Pending`] = null;
        asyncStatuses.status[`${actionName}Success`] = null;
        asyncStatuses.statusMessage[`${actionName}Message`] = null;
      }
    });
    finalAsyncStore = createStore(asyncStatuses);
  }

  Object.keys(reducers).forEach((actionName) => {
    const {
      action,
      reducer,
      pending,
      done,
      fail,
      skipAsyncStore,
    } = reducers[actionName];
    if (reducer) {
      store.on(action, reducer);
    }
    if (pending) {
      store.on(action.pending, pending);
    }
    if (done) {
      store.on(action.done, done);
    }
    if (fail) {
      store.on(action.fail, fail);
    }

    if (!reducer && !skipAsyncStore) {
      addAsyncActionHandlers(finalAsyncStore, action, actionName);
    }
  });

  storeResetReducers.forEach((actions) => {
    store.reset(actions);
  });

  asyncResetReducers.forEach((actions) => {
    finalAsyncStore.reset(actions);
  });

  return combine(finalAsyncStore, store, (a, b) => ({ ...a, ...b }));
}
