import type { MouseEventHandler } from 'react';
import type {
  StyledSystemComponentProps,
} from '@metropolis-io/theme';
import {
  ButtonBase,
  ButtonPrimary,
  ButtonSecondary,
  ButtonDestructive,
} from './Button.styled';

type ButtonType = (
  | typeof ButtonBase
  | typeof ButtonPrimary
  | typeof ButtonSecondary
  | typeof ButtonDestructive
);

export type ButtonProps = StyledSystemComponentProps<ButtonType, {
  variant?: 'primary' | 'secondary' | 'destructive';
  onPress?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}>;

export function Button({
  variant,
  onPress,
  ...passProps
}: ButtonProps) {
  let ButtonComponent: ButtonType = ButtonBase;
  if (variant === 'primary') {
    ButtonComponent = ButtonPrimary;
  } else if (variant === 'secondary') {
    ButtonComponent = ButtonSecondary;
  } else if (variant === 'destructive') {
    ButtonComponent = ButtonDestructive;
  }

  return (
    <ButtonComponent
      data-testid="Button"
      onClick={onPress}
      {...passProps}
    />
  );
}

Button.defaultProps = {
  variant: null,
  htmlType: 'button',
};
